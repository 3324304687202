<template>
    <v-container fluid>
        <v-form @submit.prevent="getClients('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{ name: 'client.create' }"
                   color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12" sm="8">
                                <v-subheader class="headline">{{ $t("search_client") }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="4" class="text-right">
                                <v-btn :to="{ name: 'client.create' }" color="indigo" dark>{{ $t("create") }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="login" rules="min:3|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="login" type="text"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-card-account-details-outline"
                                                  :label="$t('login')" color="primary" clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="client" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="client" type="text"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-account" :label="$t('client_name')"
                                                  color="primary" clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="6" md="3">
                                <ValidationProvider ref="phone" rules="phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field
                                        v-model="phoneRaw"
                                        type="tel"
                                        v-mask="phoneMask"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        prepend-icon="mdi-phone"
                                        :label="$t('phone')"
                                        color="primary"
                                        clearable
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6" md="3">
                                <ValidationProvider ref="email" rules="email"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field
                                        v-model="email"
                                        type="email"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        prepend-icon="mdi-email"
                                        :label="$t('email')"
                                        color="primary"
                                        clearable
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6" md="3">
                                <ValidationProvider ref="country" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="country" :items="countryItems"
                                                    :disabled="loading" :error-messages="errors"
                                                    :search-input.sync="countrySearching"
                                                    item-text="name" item-value="id"
                                                    prepend-icon="mdi-web" :loading="loadingCountries"
                                                    @click:clear="countryItems = []"
                                                    :no-data-text="countrySearching? $t('nothing_found_by', {search: citySearching}): $t('nothing_found_country_name')"
                                                    :label="$t('country')" @click="clearCountries"
                                                    color="primary" return-object
                                                    clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6" md="3">
                                <ValidationProvider ref="city" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="city" :items="cityItems"
                                                    :disabled="loading || !country"
                                                    :error-messages="errors"
                                                    :search-input.sync="citySearching"
                                                    item-text="name" item-value="id"
                                                    prepend-icon="mdi-city"
                                                    :loading="loadingCities"
                                                    @click:clear="cityItems = []"
                                                    :no-data-text="citySearching? $t('nothing_found_by', {search: citySearching}) : $t('nothing_found_city_name')"
                                                    :label="$t('city')" @click="clearCities"
                                                    color="primary" return-object
                                                    clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-0 py-6">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems"
                                          :disabled="loading" :label="$t('items_per_page')"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          prepend-icon="mdi-counter" hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t("search") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table :headers="filteredHeaders" :items="clients"
                              :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalClients"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                              :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')"
                              class="elevation-1" hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? " (" + $t("deleted") + ")" : "" }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t("yes") : $t("no") }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click="editClient(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("edit") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="item.deleted"
                                       @click="deleteClient(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t("remotely") : $t("delete") }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-show="totalClients > perPage" v-model="page"
                              :total-visible="totalVisiblePag" :length="pageCount"
                              :disabled="loading">
                </v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {mask} from "vue-the-mask";
import debounce from "lodash/debounce";

export default {
    name: "Clients",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            fab: false,
            phoneRaw: null,
            client: null,
            email: null,
            login: null,
            clients: [],
            clientItems: [],
            sortBy: "name",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalClients: 0,
            options: {},
            loading: false,
            loadingCities: false,
            loadingCountries: false,
            country: null,
            countryItems: [],
            countrySearching: null,
            city: null,
            cityItems: [],
            citySearching: null,
            headers: [
                {
                    text: this.$t("login"),
                    align: "left",
                    sortable: true,
                    value: "login",
                },
                {
                    text: this.$t("client"),
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                {
                    text: this.$t("phone"),
                    sortable: true,
                    value: "phone",
                },
                {
                    text: this.$t("email"),
                    sortable: true,
                    value: "email",
                },
                {
                    text: this.$t("city"),
                    align: "left",
                    sortable: false,
                    value: "name_city",
                },
                {
                    text: this.$t("active"),
                    align: "center",
                    sortable: true,
                    value: "active",
                    width: 110,
                },
                {
                    text: this.$t("actions"),
                    align: "center",
                    value: "action",
                    sortable: false,
                    width: 120,
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["lang", "itemsPerPage", "perPageItems", "phoneMask"]),
        phone: function () {
            return this.changePhone(this.phoneRaw);
        },
        filteredHeaders() {
            return this.headers.filter((h) => !h.hide);
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage;
    },
    watch: {
        options: {
            handler() {
                this.getClients();
            },
            deep: false,
        },
        countrySearching: debounce(function (val) {
            if (val && !this.country) {
                this.getCountries(val);
            }
        }, 500),
        citySearching: debounce(function (val) {
            if (val && !this.city) {
                this.getCities(val);
            }
        }, 500),
    },
    methods: {
        clearCountries() {
            if (!this.country) {
                this.countryItems = [];
            }
            this.city = null;
            this.cityItems = [];
        },
        clearCities() {
            if (!this.city) {
                this.cityItems = [];
                this.city = null;
            }
        },
        editClient(item) {
            this.$router.push({
                name: "client.edit",
                params: {
                    id: item.id,
                },
            });
        },
        async getCountries(str) {
            if (str) {
                this.loadingCountries = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.country = str;
                }
                await this.$http
                    .get("admin/country", {
                        params: params,
                    })
                    .then((res) => {
                        this.countryItems = res.body.data;
                    })
                    .catch((err) => {
                        this.countryItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_countries")
                        );
                    })
                    .finally((end) => {
                        this.loadingCountries = false;
                    });
            }
        },
        async getCities(str) {
            if (str) {
                this.loadingCities = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.name = str;
                }
                await this.$http
                    .get("admin/city", {
                        params: params,
                    })
                    .then((res) => {
                        this.cityItems = res.body.data;
                    })
                    .catch((err) => {
                        this.cityItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_cities")
                        );
                    })
                    .finally((end) => {
                        this.loadingCities = false;
                    });
            }
        },
        async deleteClient(item) {
            if (confirm(this.$t("delete_client"))) {
                var _this = this;
                this.loading = true;
                await this.$http
                    .delete(`admin/client/${item.id}`)
                    .then((res) => {
                        this.$toastr.success(
                            this.$t("client_has_been_deleted")
                        );
                        this.getClients();
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("client_has_not_been_deleted")
                        );
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ]);
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message);
                            }
                        }
                    })
                    .finally((end) => {
                        this.loading = false;
                    });
            }
        },
        async getClients(type) {
            this.loading = true;
            const {sortBy, sortDesc, page, itemsPerPage} = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? "asc" : "desc";
            }
            if (page !== undefined) {
                params.page = page;
            }
            if (type === 'search') {
                params.page = 1
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.login) {
                params.login = this.login;
            }
            if (this.client) {
                params.name = this.client;
            }
            if (this.phone) {
                params.phone = this.phone;
            }
            if (this.email) {
                params.email = this.email;
            }
            if (this.country) {
                if (this.country.id) {
                    params.country = this.country.id;
                } else {
                    params.country = this.country;
                }
            }
            if (this.city) {
                if (this.city.id) {
                    params.city = this.city.id;
                } else {
                    params.city = this.city;
                }
            }
            await this.$http
                .get("admin/client", {
                    params: params,
                })
                .then((res) => {
                    this.clients = res.body.data;
                    this.page = res.body.meta.current_page;
                    this.totalClients = res.body.meta.total;
                    this.pageCount = res.body.meta.last_page;
                })
                .catch((err) => {
                    this.clients = [];
                    this.totalClients = 0;
                    this.$toastr.error(this.$t("failed_to_get_list_clients"));
                })
                .finally((end) => {
                    this.loading = false;
                });
        },
    },
};
</script>
